export const environment = {
  production: true,
  useEmulators: false,
  dev: false,
  vx: true,
  firebase: {
    apiKey: "AIzaSyBqv71F_A8kEM3KVsYYIbuLQRyPb3RHBrQ",
    authDomain: "careerscope-eagle.firebaseapp.com",
    projectId: "careerscope-eagle",
    storageBucket: "careerscope-eagle.appspot.com",
    messagingSenderId: "120877899485",
    appId: "1:120877899485:web:8022c3f6d2a51e59518534",
    measurementId: "G-BYFCHXCQ18"
  },
  evalueeInvitationURL: 'https://appvx.careerscope.com/login/',
  evalueeUsernameURL: 'https://appvx.careerscope.com/username/',
  managerInvitationURL: 'https://adminvx.careerscope.com/login/',
  portalInvitationURL: 'https://appvx.careerscope.com/portal/',
  functionsURL: `https://adminvx.careerscope.com`
};